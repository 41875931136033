import { configureStore } from "@reduxjs/toolkit";
import userData from "./slices/user";
import listIntegration from "./slices/listIntegration";

export default configureStore({
  reducer: {
    userData,
    listIntegration,
  },
});
