// ModalContent.js
import { Flex, Form, Image, Input } from "antd";
import processIMG from "../../assets/images/processBulk.svg";
import succcesIMG from "../../assets/images/successBulk.svg";
import errorIMG from "../../assets/images/failedProcess.svg";
import Spinner from "../Spinner/Spinner";

const StateContent = ({ imgSrc, title, description, children }) => (
  <Flex justify="center" vertical gap={8}>
    <Image src={imgSrc} alt="" preview={false} />
    <h5 className="mt-0 mb-0 text-center text-base font-medium">
      <Flex gap={10} justify="center">
        {title}
        {children}
      </Flex>
    </h5>
    <span className="text-sm text-center text-secondary pb-2">
      {description}
    </span>
  </Flex>
);

export const getModalContent = ({
  onProcess,
  onSuccess,
  onError,
  form,
  tableRecord,
  getRecord,
  handleFormChange,
}) => {
  if (onProcess) {
    return (
      <StateContent
        imgSrc={processIMG}
        title="Melakukan import list email"
        description="Proses import data anda kedalam sistem, harap tunggu hingga proses selesai. Jangan tutup halaman ini."
      >
        <Spinner />
      </StateContent>
    );
  } else if (onSuccess) {
    return (
      <StateContent
        imgSrc={succcesIMG}
        title="List berhasil diimport"
        description="Data list email anda berhasil diimport pada sistem kami. Selanjutnya anda dapat melakukan verifikasi daftar email tersebut."
      />
    );
  } else if (onError) {
    return (
      <StateContent
        imgSrc={errorIMG}
        title="File gagal diimport"
        description="File tersebut gagal diimport pada sistem kami, terdapat kesalahan saat mengupload. Harap coba lagi atau hubungi Tim Support kami."
      />
    );
  } else {
    return (
      <>
        Import list email{" "}
        <span className="font-medium">{tableRecord?.name}</span> dari{" "}
        {getRecord?.name}. Anda akan melakukan import{" "}
        <span className="font-medium">{tableRecord?.total}</span> alamat email
        untuk dilakukan validasi dengan Bulk Validation.
        <Form form={form} layout="vertical" onFieldsChange={handleFormChange}>
          <Form.Item
            name="list_name"
            className="pt-2"
            rules={[{ required: true, message: "Nama List is required" }]}
            label="Nama List"
          >
            <Input placeholder="ex.: List Email Customer" />
          </Form.Item>
        </Form>
      </>
    );
  }
};
