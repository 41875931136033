import Cookies from "js-cookie";
import { message } from "antd";

export const getLoginStatus = () => {
  const inCookie = Cookies.get("is-login");
  const inStorage = localStorage.getItem("is-login");
  return inCookie || inStorage === 1;
};

export const isFunction = (fn) => typeof fn === "function";

export const runFunction = (fn) => {
  if (typeof fn === "function") fn();
};

export const formatNumberWithDot = (number) => {
  return number?.toLocaleString().replace(/,/g, ".");
};

export const formatNumberWithComma = (number) => {
  return number?.toLocaleString().replace(/,/g, ",");
};

export const getStartPagin = (page, length) => {
  if (
    (!page && !length) ||
    (typeof page === "number" && typeof length === "number")
  )
    return 0;
  if (page === 0) return page;
  return page - 1 + length;
};

export const convertIDR = (number = 0) => {
  if (typeof number !== "number") return "Rp data-number-invalid";
  return `Rp${number.toLocaleString("id-ID")}`;
};
export const setStorage = (name, value) => localStorage.setItem(name, value);
export const getStorage = (name) => localStorage.getItem(name);
export const removeStorage = (name = "" || []) => {
  if (typeof name === "object" && name.length > 0)
    name.map((n) => localStorage.removeItem(n));
  else localStorage.removeItem(name);
};
export const setCookie = (name, value, options = { path: "/" }) =>
  Cookies.set(name, value, { ...options });
export const getCookie = (name) => Cookies.get(name) || false;
export const removeCookie = (name = "" || []) => {
  if (typeof name === "object" && name.length > 0)
    name.map((n) => Cookies.remove(n));
  else Cookies.remove(name);
};

export const validateRepeatPassword = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Password tidak sesuai"));
  },
});

export const validateFileBulk = ({ file }) => {
  return file && /\.(csv|txt)$/i.test(file.name) && file.size <= 200 * 1024;
};

export const BanCharacters = /^[a-zA-Z !@#$%^&*()_+{}[\]:;<>,.?~\\/-]*$/;

export const requiredRules = {
  required: true,
  message: "Harap diisi terlebih dahulu",
};

export const requiredEmail = {
  required: true,
  message: "Alamat email wajib diisi",
};

export const numberRules = {
  pattern: new RegExp(/^[0-9]+$/),
  message: "Mohon masukkan angka!",
};

export const confirmDeletionRule = (_, value) => {
  if (value !== "confirm") {
    return Promise.reject("Harap ketikkan confirm terlebih dahulu");
  } else {
    return Promise.resolve();
  }
};

export const formattedName = (record) => {
  let formatted;
  formatted = record?.name.replace(/\s+/g, "-").toLowerCase();
  return formatted;
};

export const formatEspname = (espname) => {
  let formatted = espname.replace(/-/g, " ");
  formatted = formatted
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return formatted;
};

export const noSpaceRegex = /^[^\s]+$/;

export const regexGroupName = /^[a-z0-9_]+$/;

export const regexDomain =
  /^(https?:\/\/)?[0-9a-z-_]*(\.[0-9a-z-_]+)*(\.[a-z]{2,4})+(\/[0-9a-z-_]*)*?\/?$/gim;

export const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const regexPhone = /^[0|62]{1,3}\d{7,16}/gm;

export const regexPassword =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}/[\]|\\:;"'<>,.?/_₹]).{8,}$/;

export const regexApiKey = /^[^\s]{5,}$/;

export const getSessionToken = () => {
  return window.sessionStorage.getItem("token_validation");
};

export const setCookieToken = (value) => {
  return setCookie("token_validation", value);
};

export const getCookieToken = () => {
  return getCookie("token_validation");
};

export const getCookieRefreshToken = () => {
  return getCookie("token_validation_refresh");
};

export const getTokenForced = () => {
  return getCookie("token_validation");
};

export const getRefreshTokenForced = () => {
  return getCookie("token_validation_refresh");
};

export const onEnter = (e, callback) => {
  if (!e || !e.keyCode || !e.key) return "";
  if ((e.keyCode === 13 || e.key === "Enter") && isFunction(callback))
    callback();
};

export const setLogin = (data, path, user) => {
  if (!data || !path) return false;
  if (user === "user") {
    setCookie("token_validation", data.authToken);
    setCookie("token_validation_refresh", data.refreshToken);
  }
};

export const setLogout = () => {
  removeCookie(["token_validation", "token_validation_refresh"]);
  setTimeout(() => {
    window.location.href = "/login";
  }, 150);
};

export function secondsToTime(e) {
  const m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0");

  return `${m} menit ${s} detik`;
}

export const gatherFormData = (form, callback) => {
  if (form && form.getFieldsValue && form.getFieldsError) {
    form.submit();
    let errAmount = [];
    setTimeout(() => {
      form.getFieldsError().map((e) => e.errors.length && errAmount.push(e));
      const values = form.getFieldsValue();
      if (errAmount.length < 1 && isFunction(callback)) return callback(values);
    }, 100);
    return false;
  }
  return false;
};

export const maskNPWP = (data = "") => {
  if (data === "" || data === null) return "-";
  return `${data.substring(0, 2)}.${data.substring(2, 5)}.${data.substring(
    5,
    8
  )}.${data.substring(8, 9)}-${data.substring(9, 12)}.${data.substring(
    12,
    15
  )}`;
};

export const capitalizeFirstLetter = (word = "") => {
  return word && word.charAt(0).toUpperCase() + word.slice(1);
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener, noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const splitDate = (date) => {
  const dateSplit = date.split(" ");
  const dayDate = dateSplit[0];
  const time = dateSplit[1];
  return { date: dayDate, time };
};

export const handleCopyEmail = (email) => {
  if (window.isSecureContext && navigator.clipboard) {
    window.navigator.clipboard.writeText(email);
    message.success("Email address copied to clipboard");
  } else {
    const copyEmail = document.body.appendChild(
      document.createElement("input")
    );
    copyEmail.value = email;
    copyEmail.focus();
    copyEmail.select();
    document.execCommand("copy");
    message.success("Email address copied to clipboard");
    document.body.removeChild(copyEmail);
  }
};

export const btnShowHideKey = (setHidePassword) => {
  setHidePassword((prev) => !prev);
};

export const btnCopyKey = (char, key, toggleCopyState) => {
  if (!char) return "";
  const el = document.body.appendChild(document.createElement("input"));
  el.type = "hidden";
  el.value = char;
  el.focus();
  el.select();
  document.execCommand("copy");
  window.navigator.clipboard.writeText(char);
  toggleCopyState(key);
  setTimeout(() => toggleCopyState(key), 4000);
};
