import { Card, Col, Row } from "antd";
import DashboardHeader from "../../../components/dashboard-header/DashboardHeader";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import { TableIntegration } from "../../../components/table/integration/TableIntegration";
import { useCallback, useState } from "react";
import { listIntegration } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import useGetListIntegration from "../../../utils/hooks/useGetListIntegration";
import { setListIntegration } from "../../../redux/slices/listIntegration";

const Integration = () => {
  useDocumentTitle("Integration");
  useGetListIntegration();
  const dispatch = useDispatch();
  const [loadingData, setLoadingdata] = useState(false);
  const listIntegrationData = useSelector(
    ({ listIntegration }) => listIntegration.value
  );

  const getAllListIntegration = useCallback(async () => {
    setLoadingdata(true);
    const { data, statusCode, msg } = await listIntegration();
    dispatch(setListIntegration({ data, statusCode, msg }));
    setLoadingdata(false);
  }, [dispatch]);

  return (
    <>
      <DashboardHeader title="Integration" />
      <Row className="px-3 py-3 mx-0" gutter={24}>
        <Col span={24}>
          <Card>
            <TableIntegration
              dataListIntegration={listIntegrationData}
              loadingData={loadingData}
              callbackIntegration={getAllListIntegration}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Integration;
