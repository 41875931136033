const lists = [
  {
    children: [
      // User
      { name: "authRegister", url: "user/signup", method: "POST" },
      { name: "authLogin", url: "user/login", method: "POST" },
      { name: "verifyRegister", url: "user/signup/verify", method: "POST" },
      {
        name: "resendVerification",
        url: "user/resend-verification",
        method: "POST",
      },
      { name: "forgetPassword", url: "user/forgot-password", method: "POST" },
      { name: "changePassword", url: "user/reset-password", method: "POST" },
      { name: "getUser", url: "user", method: "GET" },
      { name: "updateUser", url: "user", method: "PATCH" },
      { name: "authLogout", url: "user/logout", method: "POST" },

      // Main
      { name: "summaryEmail", url: "validate/summary", method: "GET" },
      { name: "reportEmail", url: "validate/reports", method: "GET" },
      { name: "latestValidate", url: "validate/latest/short", method: "GET" },

      // Single Validation
      { name: "validateEmail", url: "validate/single", method: "GET" },
      { name: "validateList", url: "validate/search", method: "GET" },

      // Bulk Validation
      { name: "bulkList", url: "validate/bulk/list", method: "GET" },
      { name: "bulkSummary", url: "validate/bulk/summary", method: "GET" },
      { name: "detailBulk", url: "validate/bulk/detail", method: "GET" },
      { name: "uploadFileBulk", url: "validate/bulk", method: "POST" },
      { name: "deleteListBulk", url: "validate/bulk", method: "DELETE" },
      {
        name: "startBulkValidation",
        url: "validate/bulk/start",
        method: "POST",
      },
      {
        name: "refreshProgressBulk",
        url: "validate/bulk/status",
        method: "GET",
      },

      // API Key
      { name: "apiKeyList", url: "company/api-key/lists", method: "GET" },
      { name: "addApiKey", url: "company/api-key", method: "POST" },
      { name: "deleteApiKey", url: "company/api-key", method: "DELETE" },

      // Integration
      {
        name: "listIntegration",
        url: "company/integration/list",
        method: "GET",
      },
      { name: "configIntegration", url: "company/integration", method: "POST" },
      { name: "editIntegration", url: "company/integration", method: "PATCH" },
      {
        name: "disconnectIntegration",
        url: "company/integration",
        method: "DELETE",
      },
      { name: "detailIntegration", url: "company/integration", method: "GET" },
      {
        name: "emailListIntegration",
        url: "company/integration/email-lists",
        method: "GET",
      },
      {
        name: "importListIntegration",
        url: "company/integration/import",
        method: "POST",
      },

      // Profile
      { name: "quotaDetail", url: "company/quota", method: "GET" },
      { name: "companyInformation", url: "company", method: "GET" },
      { name: "updateCompany", url: "company", method: "PATCH" },
      { name: "fillCompany", url: "company", method: "PUT" },
      { name: "contactInformation", url: "company/contacts", method: "GET" },
      { name: "setupConfig", url: "company/set-config-setup", method: "POST" },
      {
        name: "claimFreeQuota",
        url: "company/claim-free-quota",
        method: "POST",
      },
      {
        name: "changeProfilePassword",
        url: "user/change-password",
        method: "POST",
      },
    ],
  },
];

export default lists;
