import { Menu } from "antd";
import {
  ICAppstoreOutlined,
  ICLogoutOutlined,
  ICUserOutlined,
  ICFilterOutlined,
  ICApiOutlined,
  ICSyncOutlined,
} from "../../components/list/ListIcon";
import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./sidebar.less";
import { handleLogout } from "../../utils/handleFunction";
import { FileProtectOutlined } from "@ant-design/icons";

const LabelSidebar = ({ path, label }) => {
  return (
    <NavLink to={path}>
      <span className="nav-text">{label}</span>
    </NavLink>
  );
};

const Sidebar = () => {
  const { pathname: path } = useLocation();

  const [selectedMenu, setSelectedMenu] = useState("");

  const selectMenu = ({ key }) => {
    setSelectedMenu(key);
  };

  useEffect(() => {
    const userPath = path.split("/")[1];
    selectMenu({ key: userPath });
  }, [path]);

  const itemsUser = [
    {
      key: "main",
      label: <LabelSidebar path="/main" label="Dashboard" />,
      icon: <ICAppstoreOutlined />,
    },
    {
      key: "single-validation",
      label: (
        <LabelSidebar path="/single-validation" label="Single Validation" />
      ),
      icon: <ICFilterOutlined />,
    },
    {
      key: "bulk-validation",
      label: <LabelSidebar path="/bulk-validation" label="Bulk Validation" />,
      icon: <FileProtectOutlined />,
    },
    {
      key: "api-key",
      label: <LabelSidebar path="/api-key" label="API Key" />,
      icon: <ICApiOutlined />,
    },
    {
      key: "integration",
      label: <LabelSidebar path="/integration" label="Integration" />,
      icon: <ICSyncOutlined />,
    },
    {
      key: "profile",
      label: <LabelSidebar path="/profile" label="Profil" />,
      icon: <ICUserOutlined />,
    },
  ];

  return (
    <>
      <Menu
        mode="inline"
        defaultSelectedKeys={[selectedMenu]}
        className="menu-sidebar"
        selectedKeys={selectedMenu}
        onSelect={selectMenu}
        inlineIndent={16}
        items={itemsUser}
      />
      <Menu
        mode="inline"
        inlineIndent={16}
        className="btn-logout-wrapper"
        onClick={handleLogout}
        items={[
          {
            key: 0,
            label: "Keluar",
            icon: <ICLogoutOutlined />,
          },
        ]}
      />
    </>
  );
};

export default Sidebar;
