import {
  Badge,
  Button,
  Col,
  Divider,
  Flex,
  Grid,
  Row,
  Typography,
  Form,
} from "antd";
import { ICEditOutlined } from "../../list/ListIcon";
import { DisconnectOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ModalDeleteIntegration } from "../../modal/ModalDelete";
import { ModalIntegrate } from "../../modal/ModalIntegrate";

const { Text } = Typography;
const { useBreakpoint } = Grid;

export const SummaryDetail = ({
  dataDetail,
  getRecord,
  callbackDetail,
  callbackList,
}) => {
  const screens = useBreakpoint();
  const largeScreen = screens.lg;
  const [form] = Form.useForm();
  const [openModalIntegrate, setOpenModalIntegrate] = useState(false);
  const [openModalDisconnect, setOpenModalDisconnect] = useState(false);

  const handleCloseModalIntegrate = () => {
    setOpenModalIntegrate(false);
    form.resetFields();
  };

  const handleCloseModalDisconnect = () => {
    setOpenModalDisconnect(false);
    form.resetFields();
  };

  return (
    <Row gutter={[32]}>
      <Col xl={24} lg={24} md={12} sm={12} xs={12}>
        <Flex
          justify="space-between"
          align="center"
          className={`${!largeScreen ? "pb-2" : "pb-1"} lead-32`}
        >
          <Text className="text-secondary">Status</Text>
          <Badge
            status={
              dataDetail?.status === "connected"
                ? "success"
                : dataDetail?.status === "not-connected"
                ? "default"
                : "error"
            }
            text={
              dataDetail?.status === "connected"
                ? "Connected"
                : dataDetail?.status === "not-connected"
                ? "Not Connected"
                : "Invalid API Key"
            }
            className="font-medium"
          />
        </Flex>
        <Flex justify="space-between" align="center">
          <Text className="text-secondary lead-32">API Key</Text>
          <Text className="lead-32">{dataDetail?.value}***</Text>
        </Flex>
        {largeScreen && <Divider className="my-3" />}
      </Col>

      <Col xl={24} lg={24} md={12} sm={12} xs={12}>
        <Flex vertical gap={8}>
          <Button
            block
            icon={<ICEditOutlined />}
            onClick={() => {
              setOpenModalIntegrate(true);
            }}
          >
            Edit connection
          </Button>
          <Button
            block
            icon={<DisconnectOutlined />}
            danger
            ghost
            onClick={() => setOpenModalDisconnect(true)}
          >
            Disconnect
          </Button>
        </Flex>
      </Col>
      <ModalIntegrate
        open={openModalIntegrate}
        close={handleCloseModalIntegrate}
        form={form}
        record={getRecord}
        callbackDetail={callbackDetail}
        callbackList={callbackList}
        detailPage={true}
      />
      <ModalDeleteIntegration
        openDC={openModalDisconnect}
        closeDC={handleCloseModalDisconnect}
        form={form}
        record={getRecord}
        detailPage={true}
      />
    </Row>
  );
};
