import {
  Button,
  Row,
  Table,
  Tooltip,
  Typography,
  Grid,
  ConfigProvider,
  Form,
  Flex,
} from "antd";
import { ICDownloadOutlined } from "../../list/ListIcon";
import { EmptyList, InvalidApiKey } from "../../empty/EmptyComponent";
import { formatNumberWithDot } from "../../../utils/helpers";
import { useState } from "react";
import { ModalImportList } from "../../modal/ModalImportList";
import Spinner from "../../Spinner/Spinner";

const { Text } = Typography;
const { useBreakpoint } = Grid;

export const TableEmailList = ({ loadingList, dataList, getRecord }) => {
  const screens = useBreakpoint();
  const mediumScreen = screens.md;
  const [form] = Form.useForm();
  const [openModalImport, setOpenModalImport] = useState(false);
  const [tableRecord, setTableRecord] = useState(null);
  const [process, setProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState(false);

  const handleCloseModalImport = () => {
    setOpenModalImport(false);
    setTableRecord(null);
    setProcess(false);
    setSuccess(false);
    setError(false);
    setIsFormValid(false);
    form.resetFields();
  };

  const propsState = {
    isFormValid,
    process,
    success,
    error,
    setIsFormValid,
    setProcess,
    setSuccess,
    setError,
  };

  const columnEmailList = [
    {
      title: "List Name",
      dataIndex: "name",
      key: "List Name",
      width: 350,
    },
    {
      title: "Emails",
      dataIndex: "total",
      key: "Emails",
      align: "right",
      width: 100,
      render: (email) => <Text>{formatNumberWithDot(email)}</Text>,
    },
    {
      key: "actions",
      fixed: "right",
      width: !mediumScreen ? 78 : 140,
      render: (record) => (
        <Flex justify="center" align="center">
          <Tooltip title="Import">
            <Button
              type="primary"
              icon={<ICDownloadOutlined />}
              onClick={() => {
                setOpenModalImport(true);
                setTableRecord(record);
              }}
            >
              {mediumScreen && "Import"}
            </Button>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  return (
    <ConfigProvider
      renderEmpty={() => {
        if (dataList?.statusCode === 400) {
          if (dataList?.msg === "Integration not found") {
            return EmptyList({
              title: "List email tidak tersedia",
              message:
                "List email tidak ditemukan. Silakan cek kembali list email dengan layanan tersebut.",
            });
          } else if (dataList?.msg === "Invalid API Key") {
            return InvalidApiKey({
              title: "API Key tidak valid",
              message:
                "Harap memperbarui API key tersebut atau mengeceknya pada service provider yang digunakan.",
            });
          }
        } else if (dataList?.statusCode === 200 && !dataList?.data?.rows) {
          return EmptyList({
            title: "List email tidak tersedia",
            message:
              "List email tidak ditemukan. Silakan cek kembali list email dengan layanan tersebut.",
          });
        }
      }}
    >
      <Row className="pb-6">
        <Text className="text-base font-medium">Email List</Text>
      </Row>
      {loadingList ? (
        <Flex justify="center" align="center" style={{ height: "40vh" }}>
          <Spinner />
        </Flex>
      ) : (
        <Table
          columns={columnEmailList}
          dataSource={dataList?.data?.rows}
          pagination={false}
        />
      )}
      <ModalImportList
        open={openModalImport}
        close={handleCloseModalImport}
        tableRecord={tableRecord}
        form={form}
        getRecord={getRecord}
        propsState={propsState}
      />
    </ConfigProvider>
  );
};
