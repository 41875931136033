/* eslint-disable jsx-a11y/anchor-is-valid */
import { Flex, Form, Image, Input, Modal, Typography } from "antd";
import { regexApiKey, requiredRules } from "../../utils/helpers";
import { useState } from "react";
import { configIntegration, editIntegration } from "../../api";
import ModalSuccess from "./ModalSuccess";
import ModalWarning from "./ModalWarning";

const { Text } = Typography;

export const ModalIntegrate = ({
  record,
  form,
  open,
  close,
  detailPage,
  callbackIntegration,
  callbackDetail,
  callbackList,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const notIntegrated =
    record?.integrationUuid === "00000000-0000-0000-0000-000000000000";

  const handleFormChange = (_, allFields) => {
    const hasErrors = allFields.some((field) => field.errors.length > 0);
    setIsFormValid(!hasErrors && form.getFieldValue("api_key"));
  };

  const handleConfigIntegration = async () => {
    const values = form.getFieldValue();
    setBtnLoading(true);
    const { msg, statusCode } = notIntegrated
      ? await configIntegration({
          serviceUuid: record?.serviceUuid,
          value: values.api_key,
        })
      : await editIntegration({
          integrationUuid: record?.integrationUuid,
          value: values.api_key,
        });
    if (statusCode === 200) {
      setBtnLoading(false);
      ModalSuccess({
        title: notIntegrated
          ? "Integrasi layanan berhasil"
          : "Integrasi ulang layanan berhasil",
        customMessage: (
          <span>
            Integrasi {!notIntegrated && "ulang"} dengan layanan{" "}
            <span className="font-medium">{record?.name}</span> berhasil. Data
            list email berhasil terhubung dengan Aktiva Email Validator.
          </span>
        ),
        width: 500,
        type: "primary",
        onOk: () => {
          if (detailPage) {
            callbackDetail();
            callbackList();
          } else {
            callbackIntegration();
          }
          close();
          setIsFormValid(false);
        },
      });
    } else if (statusCode === 400 && msg) {
      setBtnLoading(false);
      ModalWarning({
        title: msg === "Invalid API Key" ? "API key tidak valid" : msg,
        customMessage:
          "Harap dapat mengecek kembali API key tersebut dan pastikan sesuai dan tidak salah ketik.",
        width: 500,
        type: "primary",
        onOk: () => {
          close();
          setIsFormValid(false);
        },
      });
    } else {
      setBtnLoading(false);
      ModalWarning({
        title: "Integrasi layanan gagal",
        customMessage:
          "Gagal melakukan integrasi layanan. Terdapat kendala pada sistem, harap coba lagi.",
        width: 500,
        type: "primary",
        onOk: () => {
          close();
          setIsFormValid(false);
        },
      });
    }
  };

  return (
    <Modal
      centered
      title={`Integrate with ${record?.name}`}
      okText={notIntegrated ? "Integrate" : "Re-connect"}
      open={open}
      onCancel={() => {
        close();
        setIsFormValid(false);
      }}
      width={572}
      cancelText="Batal"
      okButtonProps={{ disabled: !isFormValid }}
      onOk={handleConfigIntegration}
      confirmLoading={btnLoading}
    >
      <Form form={form} layout="vertical" onFieldsChange={handleFormChange}>
        <Text className="text-base font-medium">
          <Flex gap={8}>
            <Image
              src={record?.iconUrl}
              preview={false}
              width={20}
              height={24}
            />
            {record?.name}
          </Flex>
        </Text>
        <Flex gap={12} vertical className="pt-2">
          <Text>
            {notIntegrated ? (
              <>
                Integrasi layanan {record?.name}, hubungkan list email anda
                dengan mudah tanpa harus export-import manual.
              </>
            ) : (
              <>
                Edit integrasi layanan {record?.name}, silakan input ulang API
                key anda.
              </>
            )}
            <br />
          </Text>
          <span>
            API Key (
            <a href="#" className="text-info">
              Bagaimana cara mendapatkan API key?
            </a>
            )
          </span>
        </Flex>
        <Form.Item
          name="api_key"
          className="pt-2"
          rules={[
            requiredRules,
            {
              pattern: regexApiKey,
              message: "Harap isi API key yang valid",
            },
          ]}
        >
          <Input placeholder="Insert API key" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
