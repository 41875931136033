import { Modal } from "antd";
import { useState } from "react";
import { importListIntegration } from "../../api";
import { getModalContent } from "./ModalContent";

export const ModalImportList = ({
  form,
  open,
  close,
  tableRecord,
  getRecord,
  propsState,
}) => {
  const [responseData, setResponseData] = useState(null);
  const {
    isFormValid,
    process,
    success,
    error,
    setIsFormValid,
    setProcess,
    setSuccess,
    setError,
  } = propsState;

  const onProcess = process && !success;
  const onSuccess = !process && success;
  const onError = !process && !success && error;

  const handleFormChange = (_, allFields) => {
    const hasErrors = allFields.some((field) => field.errors.length > 0);
    setIsFormValid(!hasErrors && form.getFieldValue("list_name"));
  };

  const handleImportList = async () => {
    const valuesName = form.getFieldValue("list_name");
    setProcess(true);
    const responseImport = await importListIntegration({
      integrationUuid: getRecord?.integrationUuid,
      name: valuesName,
      uid: tableRecord?.uid,
    });
    setTimeout(() => {
      if (responseImport.statusCode === 200) {
        setResponseData(responseImport?.data);
        setSuccess(true);
        setProcess(false);
      } else if (responseImport.statusCode === 400) {
        setResponseData(responseImport);
        setError(true);
        setProcess(false);
      } else {
        setError(true);
        setProcess(false);
      }
    }, 2000);
  };

  const getTitle = () => {
    if (onProcess || onSuccess || onError) return null;
    return `Import List dari ${getRecord?.name}`;
  };

  const getOkText = () => {
    if (onSuccess) return "Mulai verifikasi";
    if (onError)
      return responseData?.statusCode === 400 ? "Oke" : "Kontak Support";
    return "Import Data";
  };

  const getCancelText = () => {
    if (onSuccess || onError)
      return responseData?.statusCode === 400 ? "Tambah Kuota" : "Tutup";
    return "Batal";
  };

  const handleCancel = () => {
    if (onSuccess || onError) {
      if (responseData?.statusCode === 400) {
        window.open(
          "https://www.aktiva.co.id/email-validator-order/",
          "_blank"
        );
        close();
      } else {
        close();
      }
    } else {
      close();
    }
  };

  const handleOk = () => {
    if (onSuccess) {
      window.location.href = `/bulk-validation/${responseData?.uuid}`;
    } else if (onError) {
      if (responseData?.statusCode === 400) {
        close();
      } else {
        window.location.href = "https://support.aktiva.co.id";
      }
    } else {
      handleImportList();
    }
  };

  return (
    <Modal
      key={onSuccess || onError}
      centered
      title={getTitle()}
      okText={getOkText()}
      cancelText={getCancelText()}
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      width={onProcess || onSuccess || onError ? 540 : 572}
      okButtonProps={{ disabled: !isFormValid }}
      closeIcon={onProcess || onSuccess || onError ? false : true}
      footer={!onProcess ? undefined : null}
      {...((onProcess || onSuccess || onError) && {
        className: "validate-modal",
      })}
    >
      {getModalContent({
        onProcess,
        onSuccess,
        onError,
        form,
        tableRecord,
        getRecord,
        handleFormChange,
        responseData,
      })}
    </Modal>
  );
};
