import {
  Badge,
  Button,
  Flex,
  Image,
  Table,
  Typography,
  Grid,
  Tooltip,
  Form,
  Dropdown,
  Skeleton,
} from "antd";
import { TableTitle } from "../../table-title/TableTitle";
import {
  ICApiOutlined,
  ICEyeOutlined,
  ICMoreOutlined,
} from "../../list/ListIcon";
import { useState } from "react";
import { ModalIntegrate } from "../../modal/ModalIntegrate";
import { ActionTableIntegrate } from "../../ActionTable";
import { useNavigate } from "react-router-dom";
import { formattedName } from "../../../utils/helpers";

const { useBreakpoint } = Grid;

export const TableIntegration = ({
  dataListIntegration,
  loadingData,
  callbackIntegration,
}) => {
  const screens = useBreakpoint();
  const mediumScreen = screens.md;
  const largeScreen = screens.lg;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [openModalIntegrate, setOpenModalIntegrate] = useState(false);
  const [recordTable, setRecordTable] = useState(null);

  const handleSearch = (value) => {
    setSearchValue(value);
    const filtered = dataListIntegration?.data?.rows.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleCloseModalIntegrate = () => {
    setOpenModalIntegrate(false);
    setRecordTable(null);
    form.resetFields();
  };

  const columnIntegration = [
    {
      title: "Service Provider",
      dataIndex: "name",
      key: "Service Provider",
      width: mediumScreen ? 768 : 300,
      render: (_, record) => (
        <Typography.Text className="font-medium">
          <Flex gap={8}>
            <Image
              src={record.iconUrl}
              preview={false}
              width={16}
              height={16}
            />
            {record.name}
          </Flex>
        </Typography.Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Status",
      width: 250,
      render: (status) => {
        return (
          <Badge
            status={
              status === "connected"
                ? "success"
                : status === "not-connected"
                ? "default"
                : "error"
            }
            text={
              status === "connected"
                ? "Connected"
                : status === "not-connected"
                ? "Not Connected"
                : "Invalid API Key"
            }
            className="font-medium"
          />
        );
      },
    },
    {
      key: "actions",
      fixed: "right",
      ...(largeScreen && { width: 248 }),
      render: (_, record) => (
        <Flex gap={4}>
          <Tooltip
            {...(!largeScreen && {
              title:
                record.status === "not-connected"
                  ? "Connect"
                  : record.status === "connected"
                  ? "Details"
                  : "Reconnect",
            })}
          >
            <Button
              type="primary"
              icon={
                record.status === "connected" ? (
                  <ICEyeOutlined />
                ) : (
                  <ICApiOutlined />
                )
              }
              {...(record.status === "connected" && { ghost: true })}
              onClick={() => {
                if (record.status === "connected") {
                  const formattedEspname = formattedName(record);
                  navigate(`${formattedEspname}`, { state: record });
                } else {
                  setOpenModalIntegrate(true);
                  setRecordTable(record);
                }
              }}
            >
              {largeScreen
                ? record.status === "not-connected"
                  ? "Connect"
                  : record.status === "connected"
                  ? "Details"
                  : "Re-connect"
                : ""}
            </Button>
          </Tooltip>
          {record.status !== "not-connected" && (
            <Tooltip
              {...(!largeScreen && {
                title: "More",
              })}
            >
              <Dropdown
                trigger={["click"]}
                overlay={
                  <ActionTableIntegrate
                    setOpenModalIntegrate={setOpenModalIntegrate}
                    open={openModalIntegrate}
                    close={handleCloseModalIntegrate}
                    form={form}
                    record={recordTable}
                    setRecordTable={setRecordTable}
                    callbackIntegration={callbackIntegration}
                  />
                }
                placement="bottomRight"
              >
                <Button
                  icon={<ICMoreOutlined />}
                  onClick={() => setRecordTable(record)}
                >
                  {largeScreen && "More"}
                </Button>
              </Dropdown>
            </Tooltip>
          )}
        </Flex>
      ),
    },
  ];
  return (
    <div>
      <TableTitle
        title="Integration Provider"
        description="List provider/service yang support untuk integrasi"
        dataJSON={dataListIntegration}
        handleSearch={handleSearch}
        searchValue={searchValue}
      />
      {!dataListIntegration || loadingData ? (
        <Skeleton active />
      ) : (
        <Table
          columns={columnIntegration}
          dataSource={
            searchValue ? filteredData : dataListIntegration?.data?.rows
          }
          {...(!mediumScreen && { scroll: { x: 650 } })}
          pagination={false}
          loading={!dataListIntegration || loadingData}
        />
      )}
      <ModalIntegrate
        open={openModalIntegrate}
        close={handleCloseModalIntegrate}
        form={form}
        record={recordTable}
        callbackIntegration={callbackIntegration}
      />
    </div>
  );
};
