import { listIntegration } from "../../api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListIntegration } from "../../redux/slices/listIntegration";

const useGetListIntegration = () => {
  const dispatch = useDispatch();
  const action = setListIntegration;
  const listIntegrationData = useSelector(
    ({ listIntegration }) => listIntegration.value
  );

  const doSet = useCallback(
    (data) => dispatch(action(data)),
    [dispatch, action]
  );

  useEffect(() => {
    const getDataUser = async () => {
      const { data, statusCode, msg } = await listIntegration();
      if (data && Object.keys(data).length) doSet({ data, statusCode, msg });
    };
    if (!listIntegrationData) getDataUser();
  }, [listIntegrationData, doSet]);
};

export default useGetListIntegration;
