import Cookies from "js-cookie";
import MainService from "./base";
import { getCookie } from "../utils/helpers";

const handleErrorResponse = (error = "API error handle response") =>
  console.error("API ERROR: ", error);

const handleGETRequest = async (api, body) => {
  const {
    result: {
      body: { data, msg, status, statusCode },
    },
  } = await MainService(api)
    .doRequest({ query: body })
    .then((result) => result)
    .catch((errorGeneral) => {
      handleErrorResponse(`${api}: ${errorGeneral}`);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorGeneral,
      };
    });

  return {
    data,
    msg,
    status,
    statusCode,
  };
};

const handlePOSTRequest = async (
  api,
  body,
  asFormData = false,
  keyUnite = "",
  useQuery = false
) => {
  const formData = new FormData();
  let actualBody = { ...body };

  if (asFormData) {
    for (const key in body) {
      // reunite array data to existing formData
      if (keyUnite === key && typeof body[key] === "object")
        body[keyUnite].map((i) => formData.append(keyUnite, i));
      else formData.append(key, body[key]);
    }
    actualBody = formData;
  }

  const {
    result: {
      body: { statusCode, data, error, msg, status },
    },
  } = await MainService(api)
    .doRequest({
      query: useQuery ? { ...body } : {},
      body: actualBody,
      hooks: {
        before({ payload, next }) {
          const newPayload = { ...payload };
          if (asFormData) delete newPayload.headers["Content-Type"];
          next(newPayload);
        },
      },
    })
    .then((result) => result)
    .catch((errorResponse) => {
      handleErrorResponse(`${api}: ${errorResponse}`);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorResponse,
      };
    });

  if (error) {
    if (error.code === 102 || error.code === 406) {
      Cookies.remove("is-login");
      alert("10001", "Mohon untuk login kembali", "error");
      const currentLocation = encodeURIComponent(
        window.location.pathname + window.location.search
      );
      window.location.href = `/login?from=${currentLocation}`;
    }
  }

  return {
    statusCode,
    data,
    msg,
    status,
    error,
  };
};

const handleDELETERequest = async (api, body) => {
  const {
    result: {
      body: { data, error, msg, status, statusCode },
    },
  } = await MainService(api)
    .doRequest({ body: body })
    .then((result) => result)
    .catch((errorGeneral) => {
      handleErrorResponse(`${api}: ${errorGeneral}`);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorGeneral,
      };
    });

  return {
    data,
    error,
    msg,
    status,
    statusCode,
  };
};

const handleUpdateRequest = async (api, body) => {
  const {
    result: {
      body: { statusCode, data, error, msg, status },
    },
  } = await MainService(api)
    .doRequest({
      body: body,
      hooks: {
        before({ payload, next }) {
          const token = getCookie("token_validation");
          const newPayload = { ...payload };
          newPayload.headers = {
            ...payload.headers,
            Authorization: `Bearer ${token}`,
          };
          next(newPayload);
        },
      },
    })
    .then((result) => result)
    .catch((errorResponse) => {
      handleErrorResponse(`${api}: ${errorResponse}`);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorResponse,
      };
    });

  if (error) {
    if (error.statusCode === 102 || error.statusCode === 406) {
      Cookies.remove("is-login");
      alert("10001", "Mohon untuk login kembali", "error");
      const currentLocation = encodeURIComponent(
        window.location.pathname + window.location.search
      );
      window.location.href = `/login?from=${currentLocation}`;
    }
  }

  return {
    statusCode,
    data,
    msg,
    status,
    error,
  };
};

/** Edit after this part */

// User
export const authRegister = ({ email, name, password }) =>
  handlePOSTRequest("authRegister", { email, name, password });
export const authLogin = ({ email, password }) =>
  handlePOSTRequest("authLogin", { email, password });
export const verifyRegister = (token) =>
  handlePOSTRequest("verifyRegister", token, false, "", true);
export const resendVerification = () => handlePOSTRequest("resendVerification");
export const forgetPassword = (email) =>
  handlePOSTRequest("forgetPassword", email);
export const changePassword = ({ password, passwordConfirm }) =>
  handlePOSTRequest(
    "changePassword",
    { password, passwordConfirm },
    false,
    "",
    true
  );
export const getUser = (uuid) => handleGETRequest("getUser", uuid);
export const updateUser = ({ name, phone }) =>
  handleUpdateRequest("updateUser", { name, phone });
export const authLogout = (refreshToken) =>
  handlePOSTRequest("authLogout", refreshToken);

// Main
export const summaryEmail = (range) => handleGETRequest("summaryEmail", range);
export const reportEmail = (range) => handleGETRequest("reportEmail", range);
export const latestValidate = () => handleGETRequest("latestValidate");

// Single Validation
export const validateEmail = ({ email, type, force }) =>
  handleGETRequest("validateEmail", { email, type, force });
export const validateList = ({ limit, page, ...filter }) =>
  handleGETRequest("validateList", { limit, page, ...filter });

// Bulk Validation
export const bulkList = ({ limit, page, ...filter }) =>
  handleGETRequest("bulkList", { limit, page, ...filter });
export const bulkSummary = () => handleGETRequest("bulkSummary");
export const detailBulk = (uuid) => handleGETRequest("detailBulk", uuid);
export const uploadFileBulk = ({ name, file }) =>
  handlePOSTRequest("uploadFileBulk", { name, file }, true);
export const deleteListBulk = (uuid) =>
  handleDELETERequest("deleteListBulk", uuid);
export const startBulkValidation = ({ uuid, type }) =>
  handlePOSTRequest("startBulkValidation", { uuid, type });
export const refreshProgressBulk = (uuid) =>
  handleGETRequest("refreshProgressBulk", uuid);

// API Key
export const apiKeyList = () => handleGETRequest("apiKeyList");
export const addApiKey = ({ name, description }) =>
  handlePOSTRequest("addApiKey", { name, description });
export const deleteApiKey = (uuid) => handleDELETERequest("deleteApiKey", uuid);

// Integration
export const listIntegration = () => handleGETRequest("listIntegration");
export const configIntegration = ({ serviceUuid, value }) =>
  handlePOSTRequest("configIntegration", { serviceUuid, value });
export const editIntegration = ({ integrationUuid, value }) =>
  handleUpdateRequest("editIntegration", { integrationUuid, value });
export const disconnectIntegration = (integrationUuid) =>
  handleDELETERequest("disconnectIntegration", integrationUuid);
export const detailIntegration = (integrationUuid) =>
  handleGETRequest("detailIntegration", integrationUuid);
export const emailListIntegration = (integrationUuid) =>
  handleGETRequest("emailListIntegration", integrationUuid);
export const importListIntegration = ({ integrationUuid, uid, name }) =>
  handlePOSTRequest("importListIntegration", { integrationUuid, uid, name });

// Profile
export const quotaDetail = () => handleGETRequest("quotaDetail");
export const companyInformation = () => handleGETRequest("companyInformation");
export const updateCompany = ({ name, phone, address, website }) =>
  handleUpdateRequest("updateCompany", { name, phone, address, website });
export const fillCompany = ({
  name,
  phone,
  address,
  website,
  validationEstimate,
  role,
  CompanyUuid,
}) =>
  handleUpdateRequest("fillCompany", {
    name,
    phone,
    address,
    website,
    validationEstimate,
    role,
    CompanyUuid,
  });
export const contactInformation = () => handleGETRequest("contactInformation");
export const setupConfig = (companyUuid) =>
  handlePOSTRequest("setupConfig", companyUuid);
export const claimFreeQuota = (companyUuid) =>
  handlePOSTRequest("claimFreeQuota", companyUuid);
export const changeProfilePassword = ({
  password,
  passwordConfirm,
  oldPassword,
}) =>
  handlePOSTRequest("changeProfilePassword", {
    password,
    passwordConfirm,
    oldPassword,
  });
