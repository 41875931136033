import { createSlice } from "@reduxjs/toolkit";

export const listIntegration = createSlice({
  name: "listIntegration",
  initialState: {
    value: false, // default state
    code: false,
  },
  reducers: {
    // reducers action
    setListIntegration: (state, { payload, code }) => {
      state.value = payload;
      state.code = code;
    },
  },
});

export const { setListIntegration } = listIntegration.actions;
export default listIntegration.reducer;
