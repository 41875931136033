import magnifierEmpty from "../../assets/images/magnifier.svg";
import lineEmpty from "../../assets/images/line_empty.svg";
import notValidKey from "../../assets/images/api_key_not_valid.svg"
import { ICInfoCircleOutlined } from "../list/ListIcon";
import { Divider } from "antd";

export const EmptyWrapper = ({
  image,
  message,
  className = "",
  imgMargin = "mb-1",
  title,
  msgClassName,
  type = "magnifier",
}) => {
  const typeImg = type === "line" ? lineEmpty : magnifierEmpty;
  const imageSrc = image || typeImg;
  const titleEmpty = title || "Data belum tersedia";

  return (
    <div className={`flex ${className}`}>
      <div className="flex flex-col items-center text-center m-auto w-full empty-wrapper">
        <img src={imageSrc} alt="Empty Chart" className={imgMargin} />
        <p className="mb-1 text-base font-medium">{titleEmpty}</p>
        <p className={`mb-0 text-secondary font-normal ${msgClassName}`}>
          {message}
        </p>
      </div>
    </div>
  );
};

export const EmptyValidatedLatest = () => {
  return (
    <div className="flex flex-col items-center pt-2">
      <ICInfoCircleOutlined
        className="text-secondary"
        style={{ fontSize: 32, color: "#00000040" }}
      />
      <p className="font-normal mb-3 mt-3" style={{ color: "#00000040" }}>
        Data belum tersedia
      </p>
      <Divider className="mb-2 mt-2" />
    </div>
  );
};

export const SearchNotFound = ({ message }) => {
  return (
    <div
      className="flex flex-col items-center w-full"
      style={{ paddingTop: "7px" }}
    >
      <img
        src={magnifierEmpty}
        alt="Data Empty"
        className="mb-0"
        style={{ width: 258, height: 200 }}
      />
      <p
        className="mt-1 mb-1 text-primary font-sm font-medium"
        style={{ fontSize: "16px" }}
      >
        Data Not Found
      </p>
      {message}
    </div>
  );
};

export const emptyHistoryValidation = ({ title, message, page }) => {
  return (
    <div
      className={`flex flex-col items-center w-full ${
        page === "Bulk Page" ? "pt-2 pb-4" : "py-5"
      }`}
    >
      <img
        src={magnifierEmpty}
        alt="Data Empty"
        className="mb-0"
        style={{ width: 258, height: 200 }}
      />
      <p
        className="mt-1 mb-1 text-primary font-sm font-medium"
        style={{ fontSize: "16px" }}
      >
        {title}
      </p>
      {message}
    </div>
  );
};

export const EmptyApiKey = ({ title, message }) => {
  return (
    <div className="flex flex-col items-center w-full pt-6 pb-4">
      <img
        src={magnifierEmpty}
        alt="Data Empty"
        className="mb-0"
        style={{ width: 258, height: 200 }}
      />
      <p className="mt-1 mb-1 text-primary text-base font-medium">{title}</p>
      <span className="text-secondary text-center max-w-400"> {message}</span>
    </div>
  );
};

export const EmptyList = ({ title, message }) => {
  return (
    <div className="flex flex-col items-center w-full">
      <img
        src={magnifierEmpty}
        alt="Data Empty"
        className="mb-0"
        style={{ width: 258, height: 200 }}
      />
      <p className="mt-1 mb-1 text-primary text-base font-medium">{title}</p>
      <span className="text-secondary text-center max-w-400"> {message}</span>
    </div>
  );
};

export const InvalidApiKey = ({ title, message }) => {
  return (
    <div className="flex flex-col items-center w-full">
      <img
        src={notValidKey}
        alt="Data Empty"
        className="mb-0"
        style={{ width: 258, height: 200 }}
      />
      <p className="mt-1 mb-1 text-primary text-base font-medium">{title}</p>
      <span className="text-secondary text-center max-w-400"> {message}</span>
    </div>
  );
};
