import { Card, Col, Image, Row } from "antd";
import DashboardPageHeader from "../../../components/dashboard-header/DashboardPageHeader";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { detailIntegration, emailListIntegration } from "../../../api";
import { formatEspname } from "../../../utils/helpers";
import Spinner from "../../../components/Spinner/Spinner";
import { SummaryDetail } from "../../../components/card/integration/SummaryDetail";
import { TableEmailList } from "../../../components/table/integration/TableEmailList";

const DetailIntegration = () => {
  useDocumentTitle("Detail Integration");
  const { espname } = useParams();
  const Location = useLocation();
  const getRecord = Location.state;
  const [dataDetail, setDataDetail] = useState(false);
  const [dataList, setDataList] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingList, setLoadingList] = useState(false);

  const getDetailIntegration = useCallback(async () => {
    setLoadingDetail(true);
    const { data, statusCode } = await detailIntegration({
      integrationUuid: getRecord.integrationUuid,
    });
    setDataDetail({ data, statusCode });
    setLoadingDetail(false);
  }, [getRecord.integrationUuid]);

  useEffect(() => {
    getDetailIntegration();
  }, [getDetailIntegration]);

  const getIntegrationList = useCallback(async () => {
    setLoadingList(true);
    const { data, statusCode, msg } = await emailListIntegration({
      integrationUuid: getRecord.integrationUuid,
    });
    setDataList({ data, statusCode, msg });
    setLoadingList(false);
  }, [getRecord.integrationUuid]);

  useEffect(() => {
    getIntegrationList();
  }, [getIntegrationList]);

  useEffect(() => {
    if (
      dataList?.statusCode === 400 &&
      dataList?.msg === "Invalid API Key" &&
      dataDetail?.data?.status !== "invalid"
    ) {
      getDetailIntegration();
    }
  }, [dataList, dataDetail, getDetailIntegration]);

  const formattedEspname = formatEspname(espname);

  if (!dataDetail && !dataList) return <Spinner full />;
  return (
    <>
      <DashboardPageHeader title={`${formattedEspname} Integration`} />
      <Row className="px-3 py-3 mx-0" gutter={[24, 24]}>
        <Col xl={6} lg={8} md={24} sm={24} xs={24}>
          <Card
            title={`${formattedEspname}`}
            extra={
              <Image
                src={dataDetail?.data?.iconUrl}
                width={18}
                height={22}
                preview={false}
              />
            }
            loading={loadingDetail}
            className="summary-detail"
          >
            <SummaryDetail
              dataDetail={dataDetail?.data}
              getRecord={getRecord}
              callbackDetail={getDetailIntegration}
              callbackList={getIntegrationList}
            />
          </Card>
        </Col>
        <Col xl={18} lg={16} md={24} sm={24} xs={24}>
          <Card className="email-list">
            <TableEmailList
              loadingList={loadingList}
              dataList={dataList}
              getRecord={getRecord}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DetailIntegration;
