import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard.js";
import MainDashboard from "./pages/dashboard/main/MainDashboard.js";
import SingleValidation from "./pages/dashboard/validation/single/SingleValidation.js";
import BulkValidation from "./pages/dashboard/validation/bulk/BulkValidation.js";
import DetailBulkValidation from "./pages/dashboard/validation/bulk/DetailBulkValidation.js";
import Profile from "./pages/dashboard/profile/Profile.js";
import CheckLogin from "./utils/CheckLogin.js";
import Verifikasi from "./pages/auth/register/VerifikasiFlow/Verifikasi.js";
import VerifikasiProcess from "./pages/auth/register/VerifikasiFlow/VerifikasiProcess.js";
import CheckRegisterConfig from "./utils/CheckRegisterConfig.js";
import ProtectedRoutes from "./utils/ProtectedRoutes.js";
import ApiKey from "./pages/dashboard/api/ApiKey.js";
import Integration from "./pages/dashboard/integration/Integration.js";
import DetailIntegration from "./pages/dashboard/integration/DetailIntegration.js";

const Register = lazy(() => import("./pages/auth/register/Register.js"));
const Login = lazy(() => import("./pages/auth/login/Login.js"));
const ForgotPassword = lazy(() =>
  import("./pages/auth/password/ForgetPassword.js")
);
const ChangePassword = lazy(() =>
  import("./pages/auth/password/ChangePassword.js")
);
const ConfirmEmail = lazy(() =>
  import("./pages/auth/register/VerifikasiFlow/ConfirmEmail.js")
);

const Company = lazy(() => import("./pages/auth/register/Company/Company.js"));
const NotFoundPage = lazy(() => import("./components/404NotFound.js"));

const Routing = ({ user, token }) => {
  return (
    <Routes>
      <Route index element={<CheckLogin />} />
      <Route path="register">
        <Route index element={<Register />} />
        <Route element={<CheckRegisterConfig user={user} />}>
          <Route path="verifikasi" element={<Verifikasi user={user} />} />
          <Route path="company-data" element={<Company user={user} />} />
        </Route>
        <Route path="verifikasi/status" element={<VerifikasiProcess />} />
      </Route>
      <Route path="company-data" element={<Company user={user} />} />
      <Route path="forgetpass" element={<ForgotPassword />} />
      <Route path="changepassword" element={<ChangePassword />} />
      <Route path="login" element={<Login />} />
      <Route
        element={
          <ProtectedRoutes user={user} token={token}>
            <Dashboard user={user} />
          </ProtectedRoutes>
        }
      >
        <Route path="main" element={<MainDashboard />} />
        <Route path="single-validation" element={<SingleValidation />} />
        <Route path="bulk-validation" element={<BulkValidation />} />
        <Route
          path="bulk-validation/:uuid"
          element={<DetailBulkValidation />}
        />
        <Route path="api-key" element={<ApiKey />} />
        <Route path="integration" element={<Integration />} />
        <Route path="integration/:espname" element={<DetailIntegration />} />
        <Route path="profile" element={<Profile />} />
      </Route>
      <Route path="confirm-email" element={<ConfirmEmail />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routing;
